import { React } from "react";

export default function Row(params) {

    const background = {
        backgroundImage: `url(${params.photo})`,
        backgroundSize: "cover"
    }


    return (
        <div className={"paraRow " + (params.direction === 'left' ? 'inverse' : '')}>
            <div className="paraText">
                <span className="paraTitle">{params.title}</span>
                <span className="paragraphStyle">{params.caption}</span>
            </div>

            <div className="verticalSpace"></div>

            <div className="paraImage" style={background}>
                {/*<img src={params.photo} alt="" width='90%'/>*/}
            </div>
        </div>
    )
}