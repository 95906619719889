import React from "react";
import './About.css';

export default function Offering() {
    const captionStyle = {
        color: 'grey',
        marginTop: '2px',
        fontSize: '95%'
    }
    return (
        <div className="">
            <div className="aboutBody">
                <br></br>
                <div className='contactUs'>
                    <span className="paraTitle">Apply for a programme</span>
                    <br></br>
                    <br></br>
                    <span style={captionStyle}>Application window not opened yet</span>
            
            
                </div>
            </div>
        </div>
    )
}
