import {React} from 'react';
import 'cloudinary-video-player/cld-video-player.min.css';
import pic1 from '../../images/gallery/DSC_0371.webp'
import pic2 from '../../images/gallery/DSC_0346.webp'
import pic3 from '../../images/gallery/DSC_0363.webp'
import pic4 from '../../images/gallery/image.png'
import pic5 from '../../images/gallery/20240917_111545.webp'

export default function Gallery () {

    return (
        <div className='mainContent'>
            <div className={'event-gallery'}>
                <img src={pic1} width='100%' alt=''/>
                <img src={pic2} width='100%' alt=''/>
                <img src={pic4} width='100%' alt=''/>
                <img src={pic3} width='100%' alt=''/>
                <img src={pic5} width='100%' alt=''/>
            </div>
        </div>
    )
}


function Image(props) {
    return <div>

    </div>
}