import React from "react";
import './About.css';
import admission from '../../images/graduation.webp';

export default function Admission () {

    const background = {
        backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/mennonitecollege-ea.appspot.com/o/graduation.webp?alt=media&token=5c295940-074b-4390-affa-572753397462)`,
        backgroundSize: "cover",
    }

    return (
        <div className="mainContent">
            <br></br>

            <div className='contactUs'>
                <span className="paraTitle">Entry requirements</span>
                <br></br>

                <div className="paragraphStyle">
                    <ol>
                        <li>
                            The applicant must be a Christian
                        </li>
                        <br></br>

                        <li>
                            The applicant should have a sound basic level of education
                        </li>
                        <br></br>

                        <li>
                            For Diploma and Degree program –Candidates should have completed Form Four/Six or
                            equivalent, with passing grades. This program is taught in English.
                        </li>
                        <br></br>

                        <li>
                            For Certificate Program –Candidates should have the mínimum of secondary education. This
                            program is taught in Kiswahili; English will be one of the elective subject taught.
                        </li>
                    </ol>
                </div>
            </div>

            <div className="backgroundImage"  style={background}></div>

            <br></br>

            <div className='contactUs'>
                <span className="paraTitle">Application Requirements</span>
                <br></br>

                <div className="paragraphStyle">
                    <ol>
                        <li>
                            An application must be filled and submitted to the principal of the College.
                        </li>
                        <br></br>

                        <li>
                            An applicant should include a copy of his/her certificates with the application.
                        </li>
                        <br></br>

                        <li>
                            An applicant should include two (2) recommendation letters; one from the Pastor or Church
                            leader of the congregation which he/she is attending; one from previous employer.
                        </li>
                    </ol>

                </div>

            </div>

            <div className="horizontalDivider"></div>

            {/* OUR SERVICES */}
            <div className='contactUs paragraphStyle'>
                <span className="paraTitle">Fee Structure</span>
                <br></br>
                <br></br>

                <table className="table">
                    <thead>
                    <tr>
                        <th>SEMESTER</th>
                        <th>Certificate in Theology (1 year)</th>
                        <th>Diploma in Theology (2 years)</th>
                        <th>Degree in Theology (3 years)</th>
                    </tr>
                    </thead>

                    <tbody>

                    <tr>
                        <td><b>I</b></td>
                        <td>400,000</td>
                        <td>400,000</td>
                        <td>400,000</td>
                    </tr>

                    <tr>
                        <td><b>II</b></td>
                        <td>400,000</td>
                        <td>400,000</td>
                        <td>400,000</td>
                    </tr>

                    <tr>
                        <td><b>III</b></td>
                        <td>400,000</td>
                        <td>400,000</td>
                        <td>400,000</td>
                    </tr>


                    <tr>
                        <td><b>IV</b></td>
                        <td>400,000</td>
                        <td>400,000</td>
                        <td>400,000</td>
                    </tr>


                    <tr>
                        <td><b>TOTAL</b></td>
                        <td><b>1,600,000</b></td>
                        <td><b>3,200,000</b></td>
                        <td><b>4,800,000</b></td>
                    </tr>
                    </tbody>
                </table>

                <br></br>
                <br></br>

                <b>ACADEMIC CALENDAR</b>

                <div className="paragraphStyle">
                    <ul>
                        <li>Semester One – <b>January</b></li>
                        <li>Semester Two – <b>April</b></li>
                        <li>Semester Three – <b>July</b></li>
                        <li>Semester Four – <b>November</b></li>

                    </ul>
                </div>


                <br></br>
            </div>

            <br></br>


        </div>
    )
}
