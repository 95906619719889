import React from "react";
import Row from "../../widgets/Row";
import './About.css';

import logo2 from '../../images/logo.png';

export default function About() {

    const background = {
        backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/mennonitecollege-ea.appspot.com/o/20240917_111232.jpg?alt=media&token=81c51f15-85ba-48b5-b107-53df3dab0eb5)`,
        backgroundSize: "cover",
        filter: 'grayscale(100%)'
    }

    return (
        <div className="mainContent">

            <br></br>

            <div className='contactUs'>
                <div class="center"><img src={logo2} alt="" width='150'/></div>
                <br></br>
                <span className="paraTitle">Background of the Institution</span>

                <div className="paragraphStyle">
                    <p>
                        <p>The Mennonite Theological College of East Africa (MTCEA) was founded in 1936 by the Board of Trustees of the Kanisa Mennonite Tanzania (KMT). Since its inauguration, the college has provided theological education, from certificate to diploma levels, to a wide range of KMT pastors and evangelists across East Africa and beyond. KMT boasts a rich history of decades-long service to its congregation, marked by significant achievements in both spiritual and physical well-being. This success can be attributed to the organization's clear vision, mission, values, and strategic initiatives, as outlined in the institutional Strategic Plan for 2024-2028.</p>

                       
                    </p>
                </div>
                
                
                <br></br>
                <span className="paraTitle">Registration</span>
                <br></br>

                <div className="paragraphStyle">
                    <p>
                        <p>MCEA is registered institution operating under the business registration act, 213 with registration number 584902. Similarly.</p>

                       
                    </p>
                </div>
            </div>

            <div className="backgroundImage" style={background}></div>
            <br></br>

            <div>
                <span className="paraTitle">Location</span>
                <p className="paragraphStyle">
            The college occupies 26 acres within the Nyabange Church premises, nestled in the Nyabange foothills, 9.5 kilometres East of Musoma Municipality. Situated 350 meters from the Musoma-Mwanza road and 400 meters from the shores of Lake Victoria, the campus enjoys moderate temperatures of 17 to 29 degrees Celsius. Its proximity to the Musoma Municipality suburb ensures accessibility by air, ferry, and highway from other regions of Tanzania and East Africa.
                        </p>
            </div>

           

            <br></br>

            <div className={'highlightSection mainContainer'}>
                <div className={"mainContent"}>
                    <div className="paraText horizontalMargin">
                        <br></br>
                        <span className="whiteText paraTitleLighter">Objectives of the College</span>
                        <span className="whiteText paragraphStyle">The Broad objective of MCEA is to be the center of excellence in providing quality technical education through training in short and long courses and tailor-made programmers in certificates and diploma.

                        <ul>
                                <li>To promote excellence in education and create an effective human resource.</li>
                                <li>To promote innovative methods of teaching-learning and evaluation.
                                </li>
                                <li>Development of a networking system with other institution of higher learning and multiple stakeholders.</li>
                                <li>Enhancing tie with the community via outreach entrepreneurial programmes.</li>
                                <li>To impart value based education with emphasis on self-discipline and character building</li>
                            </ul><br></br>
                        </span>
                    </div>
                </div>

            </div>

            <br></br>
            <div className='contactUs'>
                <span className="paraTitle">Master Plan</span><br></br>
                <p><b>Major components considered in the Master Plan for MTCEA</b></p>
                <div className="horizontalDivider"></div>
                <br></br>

                <b>SHORT TERM</b>

                <div className="paragraphStyle">
                    <ol>
                        <li>
                            Annual Fund Raising Plan
                            <ul>
                                <li>Establish ”more Friends of the College”</li>
                                <li>Solicitation to individuals/groups,business men/women, NGOs, asking for their
                                    regular assistance and contributions.
                                </li>
                                <li>Harambee (through MTCEA day, once a year)</li>
                            </ul><br></br>
                        </li>
                        <li>
                            Security of the College premises.
                            <ul>
                                <li>Enclose the premises with a fence</li>
                            </ul>
                            <br></br>
                        </li>
                        <li>
                            Maintenance of Existing Buildings
                        </li>
                        <br></br>
                        <li>
                            Expansion of Educational Services
                            <ul>
                                <li>Begin working on plans for the College to become accredited through NACTVATE</li>
                                <li>Establish other courses for instance Business Administration, etc.</li>
                            </ul>
                        </li>
                    </ol>
                </div>
                <div className="horizontalDivider"></div>
                <br></br>

                <b>LONG TERM</b>

                <div className="paragraphStyle">
                    <ol>
                        <li>
                            New buildings to be renovated
                            <ul>
                                <li>Administration Building</li>
                                <li>Dinning Hall</li>
                                <li>Dormitory for Women</li>
                            </ul><br></br>
                        </li>
                        <li>
                            Form a Scholarship Plan for Students
                            <ul>
                                <li>Establish scholarship funds based on criteria of academic achievement, major and
                                    career interest.
                                </li>
                            </ul>
                            <br></br>
                        </li>
                        <li>
                            Have the College’s own private means of transportation (Vehicle)
                        </li>

                    </ol>
                </div>
            </div>


        </div>
    )
}

function Contact(props) {
    return (
        <div className="contactRow">
            <div className='contactIcon'>{props.icon}</div>
            <span className='contacts'>{props.contacts}</span>
        </div>
    )
}