import logo from './logo.svg';
import './App.css';
import Home from './screens/Home';
import theme from './Theme';
import { BrowserRouter } from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
      
        <BrowserRouter>
          <Home/>
        </BrowserRouter>
      
      </div>
    </ThemeProvider>
  );
}

export default App;
