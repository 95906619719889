import React from "react";
import './Departments.css';
import emmanuel from '../../images/Emmanuel.png';
import mkama from '../../images/Mkama.png';
import profile from '../../images/profile.png';
import daniel from '../../images/Daniel.png';

export default function Administration() {
    return (
        <div className='mainContent'>

            <div className='contactUs'>
                <br></br>
                <span className="paraTitle">College Administrative System</span>
                <br></br>

                <div className="paragraphStyle">

                    <p>
                        <p>Based on the organizational structure of MCEA, the institution is headed by the Chief executive officer (college Principal) who is answerable to the College Governing board. The Chief executive officer serves also as the Secretary of Governing board. Under the Chief executive officer are heads of different Departments as demonstrated in the attached copy of the college organization structure.</p>
                    </p>
                </div>
            </div>
            <div className='departments'>
                <Department
                    title='Rev. Musa Nyamasa Marwa'
                    caption="Principal"
                    photo={profile}
                    direction='left'
                />
                <Department
                    title='Pst. Daniel Joseph Migera'
                    caption="Head of Academics"
                    photo={daniel}
                    direction='left'
                />
                <Department
                    title='Dr. Mkama Manyama'
                    caption="Board Chairman"
                    photo={mkama}
                    direction='left'
                />
                <Department
                    title='Pst. Emmanuel Hagai'
                    caption="General Secretary"
                    photo={emmanuel}
                    direction='left'
                />
            </div>

            <br></br>
            <span className="paraTitle">Instructors</span>
            <div className='departments'>
            
                <Department
                    title='Maiga Nchama Maiga'
                    caption="Instructor"
                    photo={profile}
                    direction='left'
                />
                <Department
                    title='William Mabanga Jackson'
                    caption="Instructor"
                    photo={profile}
                    direction='left'
                />
                <Department
                    title='Ask Albert Jella Randa'
                    caption="Instructor"
                    photo={profile}
                    direction='left'
                />
                 <Department
                    title='Pst. Thomas Mufungo Musiba'
                    caption="Instructor"
                    photo={profile}
                    direction='left'
                />
                
            </div>

            

            <br></br>
            <br></br>
        </div>
    )
}


function Department(params) {

    const background = {
        backgroundImage: `url(${params.photo})`,
        backgroundSize: "cover"
    }

    const captionStyle = {
        color: 'grey',
        marginTop: '2px',
        fontSize: '90%',
        // fontFamily: 'Lora'
    }


    return (
        <div className={"paraRow department " + (params.direction === 'left' ? 'inverse' : '')}>
            <div className="paraText">
                <span className="departmentTitle">{params.title}</span>
                <span style={captionStyle}>{params.caption}</span>
            </div>

            <div className="verticalSpace"></div>

            <div className="">
                <img src={params.photo} alt="" width='50'/>
            </div>
        </div>
    )
}