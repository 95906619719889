import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#FFFFFFFF",
        },

        secondary: {
            main: "#ff3001",
        },

        white: {
            main: "#ffffff"
        }
    },

    typography: {
        fontFamily: "Roboto",
    }
})

export default theme;