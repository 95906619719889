import React from "react";
import './HomePage.css';
import Row from '../../widgets/Row';
import logo from '../../images/image.png';
import pic1 from '../../images/20240917_111232.jpg'
import pic2 from '../../images/DSC_0363.JPG'
import pic3 from '../../images/DSC_0354.JPG'
import FreeRow from "../../widgets/FreeRow";
import graduu from '../../images/vision.png'
import mission from '../../images/mission.png'

export default function HomePage() {
    return (
        <div className='mainContainer'>
            <div className="mainContent">
                {/* <img src='https://firebasestorage.googleapis.com/v0/b/mennonitecollege-ea.appspot.com/o/Home.webp?alt=media&token=bdca72b4-1bae-4d5d-bc8f-ce0eb360d690' alt="" width='100%'/> */}

                <Row
                    title="Who are we?"
                    caption="The Mennonite Theological College of East Africa (MTCEA) was founded in 1936 by the Board of Trustees of the Kanisa Mennonite Tanzania (KMT). Since its inauguration, the college has provided theological education, from certificate to diploma levels, to a wide range of KMT pastors and evangelists across East Africa and beyond. KMT boasts a rich history of decades-long service to its congregation, marked by significant achievements in both spiritual and physical well-being. This success can be attributed to the organization's clear vision, mission, values, and strategic initiatives, as outlined in the institutional Strategic Plan for 2024-2028."
                    photo={pic1}
                    direction='right'
                />

                <div className="horizontalDivider"></div>


                <Row
                    title="Purpose"
                    caption="The purpose for the college is to provide a Certificate level, a Diploma level and a Bachelor of Theology kevel of Education to persons with spiritual maturity and commitment to Christian life and the Church. To prepare pastors and others for ministry and leadership in the Church and also in the Community."
                    photo={pic2}
                    direction='left'
                />

<div className="horizontalDivider"></div>


                <Row
                    title="Curriculum"
                    caption="The College has been training Pastors, and Deaconsof the Mennonite Church along with lay church leaders and other interested people.
Presently, the College offers eight weeks terms for four terms a year for anyone (inter-denominational) who wishes to study at the college. Both Certificate level, Diploma level and Bachelor of theology level run simultaneously."
                    photo={pic3}
                    direction='right'
                />
            </div>

            <div className={'highlightSection mainContainer'}>
                <div className={"mainContent"}>
                    <div className="paraText horizontalMargin">
                        <div className={'center'}><img src={logo} alt="" width='30%'/></div>
                        <br></br>
                        <br></br>
                        <span className="whiteText paraTitleLighter">The Purpose for the College</span>
                        <span className="whiteText paragraphStyle">Originally established to prepare religious leaders for church ministry, MTCEA has historically focused on certificate and diploma programs, nurturing spiritual growth and commitment among its students. Recognizing the evolving needs of the community, the college is strategically expanding its reach by pursuing NACTVET registration, aiming to graduate holistic leaders equipped to address both spiritual and secular challenges. This transformation will empower individuals from varied backgrounds, enabling them to contribute meaningfully to society.</span>
                    </div>
                </div>

            </div>

            <br></br>

            <div className={'mainContent'}>
                <FreeRow
                    title={'Vision Statement'}
                    caption="To be a center emphasizes high-quality education to enable citizens to address complex societal issues effectively"
                    photo={graduu}
                    direction={'left'}

                    />


<div className="horizontalDivider"></div>

                <FreeRow
                    title={'Mission Statement'}
                    caption="To put in place and strengthen structures and procedures which will enable a country to get educated and continuous learning Tanzanians that add value in National development."
                    photo={mission}
                    direction={'right'}

                    />
            </div>
        </div>
    )
}